<template>
  <div id="app">
    <router-view />
    <transition name="fade">
      <footer v-if="imageLoad" :class="[$route.name]">
        <div class="stick-row-container">
          <div class="flex-container stick-row">
            <div class="stick white"></div>
            <div class="stick pink"></div>
          </div>
        </div>
        <div class="border-bottom"></div>
        <div class="boder-bottom-shadow"></div>
        <div class="wood"></div>
      </footer>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageLoad: false,
      allImages: [
        "quiz/1-아이콘.png",
        "quiz/1-질문지.png",
        "quiz/2-아이콘.png",
        "quiz/2-질문지.png",
        "quiz/3-아이콘.png",
        "quiz/3-질문지.png",
        "quiz/4-아이콘.png",
        "quiz/4-질문지.png",
        "quiz/5-아이콘.png",
        "quiz/5-질문지.png",
        "quiz/6-아이콘.png",
        "quiz/6-질문지.png",
        "quiz/7-아이콘.png",
        "quiz/7-질문지.png",
        "quiz/8-아이콘.png",
        "quiz/8-질문지.png",
        "quiz/9-아이콘.png",
        "quiz/9-질문지.png",
        "quiz/10-아이콘.png",
        "quiz/10-질문지.png",
        "quiz/11-아이콘.png",
        "quiz/11-질문지.png",
        "quiz/12-아이콘.png",
        "quiz/12-질문지.png",
        "loading/가1.png",
        "loading/가2.png",
        "loading/가3.png",
        "loading/기1.png",
        "loading/기2.png",
        "loading/니1.png",
        "loading/니2.png",
        "loading/다1.png",
        "loading/다2.png",
        "loading/다3.png",
        "loading/다4.png",
        "loading/딩1.png",
        "loading/딩2.png",
        "loading/딩3.png",
        "loading/딩4.png",
        "loading/딩5.png",
        "loading/로1.png",
        "loading/로2.png",
        "loading/로3.png",
        "loading/로4.png",
        "loading/부1.png",
        "loading/부2.png",
        "loading/부3.png",
        "loading/부4.png",
        "loading/부5.png",
        "loading/생1.png",
        "loading/생2.png",
        "loading/생3.png",
        "loading/생4.png",
        "loading/생5.png",
        "loading/생6.png",
        "loading/생7.png",
        "loading/입1.png",
        "loading/입2.png",
        "loading/입3.png",
        "loading/입4.png",
        "loading/입5.png",
        "loading/점1.png",
        "loading/점2.png",
        "loading/점3.png",
        "loading/중1.png",
        "loading/중2.png",
        "loading/중3.png",
        "loading/증4.png",
        "loading/증5.png",
        "loading/증6.png",
        "result/결과지-감자깡.png",
        "result/결과지-고구마깡.png",
        "result/결과지-꿀꽈배기.png",
        "result/결과지-닭다리.png",
        "result/결과지-바나나킥.png",
        "result/결과지-별따먹자.png",
        "result/결과지-새우깡.png",
        "result/결과지-스낵고로고.png",
        "result/결과지-양파링.png",
        "result/결과지-오징어집.png",
        "result/결과지-옥수수깡.png",
        "result/결과지-인디안밥.png",
        "result/결과지-자갈치.png",
        "result/결과지-쫄병스낵.png",
        "result/결과지-포스틱.png",
        "result/결과지-포테토칩곱창이핫해.png",
        "result/결과지-프레첼.png"
      ]
    };
  },
  created() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      try {
        Promise.all(
          this.allImages.map(async src => {
            return await this.preLoadImage(src);
          })
        )
          .then(() => {
            this.imageLoad = true;
          })
          .catch(e => {
            console.error(e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    preLoadImage(src) {
      return new Promise(resolve => {
        let img = new Image();
        img.src = `https://snackgotest-ns.com/image/${src}`;
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(true);
        };
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#app {
  height: 100%;
  margin: 0;
}
footer {
  margin-bottom: -30px;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent 10%, #c1a868 25%);
  &.Result {
    display: none;
  }
}
.stick-row-container {
  background-color: #004f32;
}
.stick-row {
  margin: auto;
  width: 90px;
  gap: 6px;
  position: relative;
  left: -60px;
}
.stick {
  position: relative;
  width: 47px;
  height: 8px;
  border-radius: 3px;
  &.white {
    background: white;
  }
  &.pink {
    background: #fab2c1;
  }
}
.border-bottom {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 22px;
  background-color: #673500;
  border-radius: 7px;
  border: 2px solid #000000;
  z-index: 2;
}
.boder-bottom-shadow {
  background: #3b3221;
  border-radius: 7px;
  height: 22px;
  width: 100%;
  margin-left: 3px;
  margin-top: -11px;
  position: relative;
  z-index: 1;
}
.wood {
  background-image: url("/image/main/wood.png");
  background-position-y: -28px;
  height: 102px;
  position: relative;
  z-index: 0;
  background-repeat: repeat-x;
}
</style>